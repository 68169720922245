<template>
    <div>
        <div class="container-fluid d-flex flex-row flex-wrap p-0 my-2 mx-0 align-items-center justify-content-end">
            <b-button v-ripple.400 variant="primary" @click="isAddingProduct=!isAddingProduct" :disabled="isAddingProduct">
                <feather-icon icon="PlusIcon" size="16"/>
                <span class="align-middle">Add product</span>
            </b-button>
        </div>

        <div class="container-fluid">
            <transition appear name="fade" mode="out-in">
                <inventory-base-table v-if="!isAddingProduct" key="inv-base-table"/>
                <add-product v-if="isAddingProduct" key="inv-add-product" @cancel-add-product="isAddingProduct=!isAddingProduct"/>
            </transition>
        </div>
    </div>
</template>

<script>
    import {
        BButton,
    } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Ripple from 'vue-ripple-directive'
    import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
    import InventoryBaseTable from './InventoryBaseTable.vue'
    import AddProduct from '@/views/forms/admin/AddProduct.vue'

    export default {
        components: {
            BButton,
            FeatherIcon,
            InventoryBaseTable,
            AddProduct,
        },
        directives: {
            Ripple
        },
        data() {
            return {
                isAddingProduct: false,
            }
        },
        methods: {}
    }
</script>