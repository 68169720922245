<template>
    <div>
        <b-overlay variant="transparent" blur="2px" no-wrap :show="show"/>
        <transition appear name="fade" mode="out-in">
            <b-row v-if="!show" key="table-row">
                <b-card no-body>
                    <b-card-header class="p-1">
                        <!-- Page selector -->
                        <!-- <b-col md="4" sm="4" class="my-1">
                            <b-form-group class="mb-0" label="Rows" label-cols-sm="3" label-align-sm="right" label-size="md" label-for="perPageSelect">
                                <b-form-select id="perPageSelect" size="md" class="w-50"/>
                            </b-form-group>
                        </b-col> -->

                        <!-- Sort picker -->
                        <b-col sm="12" md="4" class="my-1">
                            <b-form-group class="mb-0" label="Sort" label-cols-sm="3" label-align-sm="right" label-size="md" label-for="sortBySelect">
                                <b-input-group size="md">
                                    <b-form-select id="sortBySelect" class="w-75">
                                        <template v-slot:first>
                                            <option value="">---</option>
                                        </template>
                                    </b-form-select>

                                    <b-form-select size="md" class="w-25">
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <!-- Search box -->
                        <b-col sm="12" md="8" class="my-1">
                            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="md" label-for="filterInput" class="mb-0">
                                <b-input-group size="md">
                                    <b-form-input id="filterInput" type="search" placeholder="Type to search"/>
                                    <b-input-group-append>
                                        <b-button variant="secondary" v-ripple.400 @click="filter = ''">
                                            Clear
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-card-header>

                    <!-- Table proper -->
                    <b-col cols="12 p-0">
                        <b-table striped hover responsive :items="items" :fields="fields">
                            <template #cell(quantity)="data">
                                <span v-bind:class="{'text-danger': data.value == 0}">{{ data.value }}</span>
                            </template>
                            <template #cell(status)="data">
                                <b-badge variant="success" v-if="data.value == 'active'">
                                    {{ data.value }}
                                </b-badge>
                                <b-badge variant="danger" v-if="data.value == 'sold out'">
                                    {{ data.value }}
                                </b-badge>
                                <b-badge variant="info" v-if="data.value == 'for pricing'">
                                    {{ data.value }}
                                </b-badge>
                                <b-badge variant="warning" v-if="data.value == 'on sale'">
                                    {{ data.value }}
                                </b-badge>
                            </template>
                        </b-table>
                    </b-col>
                </b-card>
            </b-row>
        </transition>
    </div>
</template>

<script>
    import {
        BOverlay,
        BTable,
        BAvatar, 
        BBadge, 
        BRow, 
        BCol, 
        BFormGroup, 
        BFormSelect, 
        BPagination, 
        BInputGroup, 
        BFormInput, 
        BInputGroupAppend, 
        BButton,
        BCard,
        BCardHeader,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BOverlay,
            BTable,
            BAvatar, 
            BBadge, 
            BRow, 
            BCol, 
            BFormGroup, 
            BFormSelect, 
            BPagination, 
            BInputGroup, 
            BFormInput, 
            BInputGroupAppend, 
            BButton,
            BCard,
            BCardHeader,
        },
        directives: {
            Ripple
        },
        created() {
            setTimeout(() => {
                this.getProducts()
            }, 100)
        },
        data() {
            return {
                show: true,
                fields: [
                    {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
                    {key: 'name', label: 'Product Name', sortable: true, class: 'align-middle'},
                    {key: 'desc', label: 'Product Description', class: 'align-middle'},
                    {key: 'price', label: 'Unit Price', sortable: true, class: 'align-middle'},
                    {key: 'quantity', label: 'On-hand Quantity', class: 'align-middle'},
                    {key: 'status', label: 'Status', class: 'align-middle'}
                ],
                items: []
            }
        },
        methods: {
            async getProducts() {
                this.$store.dispatch('getMenuItems').then(response => {
                    let res = response.data
                    if (res.result) {
                        // Check rows if a row variant could be added.
                        // To be added only if the row does not have an 'active' status -- this is to provide
                        // a sort of item highlight so actions could be performed.
                        (res.data).forEach(item => {
                            switch(item.status) {
                                case 'sold out':
                                    item._rowVariant = 'danger'
                                    break;

                                case 'on sale':
                                    item._rowVariant = 'warning'
                                    break;

                                case 'for pricing':
                                    item._rowVariant = 'info'
                                    break;

                                default:
                                    break;
                            }
                        })
                        this.items = res.data
                        this.show = false
                    }
                    else {
                        console.log('Error getting products: ', res)
                    }
                }).catch(ex => {
                    console.log('Error dispatching getMenuItems: ', ex)
                })
            }
        }
    }
</script>